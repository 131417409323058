<template>

 <b-container fluid >
     
     

    <b-row >
    <!-- col -->
   <b-col cols="12" lg="6" xl="6" > 
 <b-breadcrumb>
    <b-breadcrumb-item to="/">
      <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
      {{$t('Home')}}
    </b-breadcrumb-item>

    <b-breadcrumb-item v-if="link1" :to="link1">{{link1Name}}</b-breadcrumb-item>
    <b-breadcrumb-item v-if="link2" :to="link2">{{link2Name}}</b-breadcrumb-item>
    <b-breadcrumb-item v-if="link3" :to="link3">{{link3Name}}</b-breadcrumb-item>
    <b-breadcrumb-item v-if="link4" :to="link4">{{link4Name}}</b-breadcrumb-item>
    <b-breadcrumb-item v-if="linkActiveName" active>{{linkActiveName}}</b-breadcrumb-item>
  </b-breadcrumb>

   </b-col>
   <!-- col -->
   <b-col cols="12" lg="6" xl="6" class="text-right"> 
    <div>
        <b-button class="m-1" v-if="linkB1" :to="linkB1"  variant="info">{{linkB1Name}}</b-button>
        <b-button class="m-1" v-if="linkB2" :to="linkB2"  variant="info">{{linkB2Name}}</b-button>
        <b-button class="m-1" v-if="linkB3" :to="linkB3"  variant="info">{{linkB3Name}}</b-button>
        <b-button class="m-1" v-if="linkB4" :to="linkB4"  variant="info">{{linkB4Name}}</b-button>
        <b-button class="m-1" v-if="linkB5" :to="linkB5"  variant="info">{{linkB5Name}}</b-button>
    </div>
    </b-col>

   </b-row>

</b-container>
</template>


<script>



export default {
    name: 'SubHeader',
    props:{
      link1: Object ,link1Name: String,
      link2: Object ,link2Name: String,
      link3: Object ,link3Name: String,
      link4: Object ,link4Name: String,
      linkActiveName: String,

      linkB1: Object ,linkB1Name: String,
      linkB2: Object ,linkB2Name: String,
      linkB3: Object ,linkB3Name: String,
      linkB4: Object ,linkB4Name: String,
      linkB5: Object ,linkB5Name: String,
      
    },
     components: {
  
          
          
          
    
  }
}
</script>